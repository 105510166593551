import React, { useState } from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { CloseButton } from '../../atoms/close-button';
import { Button } from '../../atoms/button';
import { Textarea } from '../../atoms/textarea';
import { useSalesforceApi } from '../../../hooks/salesforce';

import HomeIcon from '../../../assets/home.svg';

export const SuccessForm = ({ showBtn, reference, headingEnd }) => {
	const [details, setDetails] = useState('');

	const { executeRecaptcha } = useGoogleReCaptcha();
	const { fetchSalesforce } = useSalesforceApi();

	// Create an event handler so you can call the verification on button click event or form submit
	const submitHandler = async (event) => {
		if (!executeRecaptcha) {
			return;
		}
		event.preventDefault();

		const payloadData = {
			id: reference,
			details: { extraInfo: details },
		};
		const response = await fetchSalesforce(
			executeRecaptcha,
			payloadData,
			'PATCH'
		);

		switch (response.status) {
			case 200:
				navigate('/complete', {
					state: {
						reference: response.body.identifier,
						headingEnd,
					},
				});
				break;
			case 403:
				navigate('/error', {
					state: {
						errorMessage:
							'Unfortunately, we’ve not been able to verify your Captcha and are unable to submit your form.',
					},
				});
				break;
			default:
				navigate('/error');
				break;
		}
	};

	return (
		<form onSubmit={submitHandler}>
			<Textarea
				labelText="Details about your enquiry - optional"
				id="details"
				value={details}
				name="details"
				onChange={(e) => setDetails(e.currentTarget.value)}
				rows={6}
			/>
			<div className="flex gap-2">
				<Button type="submit" variant="Main">
					Submit
				</Button>
				{showBtn ? (
					<CloseButton
						id="alt"
						destination="https://www.peninsulagrouplimited.com/"
						ariaLabel="Go to the Peninsula homepage"
					>
						<HomeIcon className="mr-2" />
						Peninsula
					</CloseButton>
				) : null}
			</div>
		</form>
	);
};

SuccessForm.defaultProps = {
	showBtn: true,
	headingEnd: '',
};

SuccessForm.propTypes = {
	showBtn: PropTypes.bool,
	reference: PropTypes.string.isRequired,
	headingEnd: PropTypes.string,
};
