import React, { useState, useRef, useEffect } from 'react';
import PropTypes, { objectOf } from 'prop-types';
import { graphql, navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Head } from '../components/templates/head';
import { NextStepHeader } from '../components/organisms/next-step-header';
import { SuccessForm } from '../components/organisms/success-form';
import { OverflowScroll } from '../components/molecules/overflow-scroll';
import { Button } from '../components/atoms/button';
import { CloseButton } from '../components/atoms/close-button';
import { Advisor } from '../components/atoms/advisor';

import RestartIcon from '../assets/restart.svg';
import HomeIcon from '../assets/home.svg';
import { gatsbyImageType } from '../types';

const advisorsData = [
	{
		key: 'ben-clapps',
		name: 'Ben Clapps',
		pronouns: 'he/him',
		jobTitle: 'Senior Business Advisor | HR and Health & Safety',
		imgAltText:
			'Portrait of Ben stood at an angle smiling, while wearing a grey blazer, black tie and white shirt',
	},
	{
		key: 'erin-grace',
		name: 'Erin Grace',
		pronouns: 'she/her',
		jobTitle: 'Senior Business Advisor | HR and Health & Safety',
		imgAltText:
			'Portrait of Erin stood at an angle smiling, while wearing black clothing and a copper coloured chain necklace',
	},
	{
		key: 'jade-raines',
		name: 'Jade Raines',
		pronouns: 'she/her',
		jobTitle: 'Senior Business Advisor | HR and Health & Safety',
		imgAltText:
			'Portrait of Jade stood at an angle smiling, while wearing a lilac shirt',
	},
	{
		key: 'liam-robinson',
		name: 'Liam Robinson',
		pronouns: 'he/him',
		jobTitle: 'Senior Business Advisor | HR and Health & Safety',
		imgAltText:
			'Portrait of Liam stood at an angle smiling, while wearing a navy blue blazer, a green tie with white patterns and a white shirt',
	},
];

// In the location state passed through here you will need to pass in either
// the complete heading text to be displayed (as one is different from booking
// a video call) or the headingEnd text that shows after '...and call you' to
// complete the heading as it can change depending on the response type selected
// plus the Salesforce ID/reference from the successful submission (to then send
// more information to Salesforce)
const SuccessPage = ({ data, location }) => {
	const path = location?.state?.path || '';
	const heading = location?.state?.heading || '';
	const headingEnd = location?.state?.headingEnd || '';
	const reference = location?.state?.reference || '';

	const advisorsRef = useRef(null);
	const [showBtn, setShowBtn] = useState(true);

	const Advisors = advisorsData.map((advisor) => {
		const image = data.allFile.edges.find((item) =>
			item.node.base.includes(advisor.key)
		);

		return (
			<Advisor
				key={advisor.key}
				name={advisor.name}
				pronouns={advisor.pronouns}
				jobTitle={advisor.jobTitle}
				image={
					<GatsbyImage
						image={getImage(image.node.childImageSharp)}
						alt={advisor.imgAltText}
						className="w-[78px] h-[78px] md:w-[125px] md:h-[125px] rounded-[100%] isolate"
						imgClassName="object-[50%_80%]"
						loading="lazy"
					/>
				}
			/>
		);
	});

	useEffect(() => {
		if (advisorsRef.current !== null) {
			setShowBtn(false);
		}
	}, []);

	useEffect(() => {
		const tracking = location?.state?.tracking || {};
		const trackingObjectIsNotEmpty = Object.keys(tracking).length > 0;
		const isWindow = typeof window !== 'undefined';
		const hasGtag = typeof window.gtag === 'function';

		if (!isWindow || !hasGtag || !trackingObjectIsNotEmpty) return;

		window.gtag('event', tracking.id, tracking.data);
	}, [location]);

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<Head title={data.site.siteMetadata.title} />
			<main id="main" className="flex flex-col min-safe-h-screen">
				<NextStepHeader
					title={
						heading !== ''
							? heading
							: `Brilliant, one of our advisors will call you
							${headingEnd}`
					}
					restartBtn={
						<Button
							variant="White"
							className="!py-xxs-f !px-l-f"
							onClick={() => navigate('/')}
						>
							<RestartIcon className="mr-2" />
							Restart
						</Button>
					}
				/>
				<section className="flex flex-col px-4 pt-10 pb-12 bg-white grow">
					<div className="wrapper-small">
						<p className="mb-12 text-xl font-centra-book">
							In preparation for the call, it would be useful if
							you could provide our team with more details about
							your matter. Don&apos;t worry if you&apos;re too
							busy, they can discuss this with you tomorrow.
						</p>

						<SuccessForm
							showBtn={showBtn}
							reference={reference}
							headingEnd={headingEnd}
						/>
					</div>
				</section>

				{path?.includes('video-call') ? (
					<>
						<section
							className="px-4 py-base-f bg-brand-pale-300"
							ref={advisorsRef}
						>
							<OverflowScroll
								title="You’ll be chatting with one of our HR experts below"
								items={Advisors}
								className="mb-8 overflow-hidden"
								borderBetween
								centreDesktop
							/>
						</section>
						<section className="px-4 py-10 text-center bg-white">
							<CloseButton
								id="alt"
								destination="https://www.peninsulagrouplimited.com/"
								ariaLabel="Go to the Peninsula homepage"
							>
								<HomeIcon className="mr-2" />
								Peninsula
							</CloseButton>
						</section>
					</>
				) : null}
			</main>
		</GoogleReCaptchaProvider>
	);
};

SuccessPage.defaultProps = {
	location: undefined,
	data: {},
};

SuccessPage.propTypes = {
	data: objectOf(
		PropTypes.shape({
			...gatsbyImageType,
			site: {
				siteMetadata: {
					title: PropTypes.string,
				},
			},
		})
	),
	// eslint-disable-next-line react/forbid-prop-types
	location: PropTypes.object,
};

export const advisorsImagesQuery = graphql`
	query AdvisorsImages {
		allFile(
			filter: {
				extension: { regex: "/(jpg)|(png)|(jpeg)/" }
				relativeDirectory: { eq: "advisors" }
			}
		) {
			edges {
				node {
					base
					childImageSharp {
						gatsbyImageData(
							formats: [AVIF, JPG, WEBP]
							width: 200
							quality: 85
							placeholder: DOMINANT_COLOR
						)
					}
				}
			}
		}
		site {
			siteMetadata {
				title
			}
		}
	}
`;

export default SuccessPage;
